import axios from "axios";

//api for user auth
export const signup = (data) => {
  return axios.post("/user/signup", data);
};

export const isUserExist = (email) => {
  return axios.post("/user/isUserExist", { email });
};

export const login = (data) => {
  return axios.post("/user/login", data);
};

export const forgotPassword = (data) => {
  return axios.post("/user/forgot-password", data);
};

export const resetPassword = ({ token, data }) => {
  return axios.post(`/user/reset-password/${token}`, data);
};

export const getWords = () => {
  return axios.get("/words");
};

export const getWordsByPagination = ({ pageParam = 1 }) => {
  return axios.get(`/words/withPagination?page=${pageParam}`);
};

export const addWord = (data) => {
  return axios.post("/words/create", data);
};

export const updateWord = ({ id, data }) => {
  return axios.patch(`/words/update/${id}`, data);
};

export const deleteWord = (id) => {
  return axios.delete(`/words/delete/${id}`);
};

export const deleteWords = () => {
  return axios.delete(`/words/deleteAll`);
};

export const getPodcasts = ({ queryKey }) => {
  const { max, lang, cat, page } = queryKey[1];

  return axios.get(
    `/podcasts?max=${max}&lang=${lang}&cat=${cat}&page=${page} `
  );
};

export const getStories = ({ queryKey }) => {
  const { max, lang, cat, page } = queryKey[1];

  return axios.get(`/stories?max=${max}&lang=${lang}&cat=${cat}&page=${page} `);
};
