import { Box, Text } from "@chakra-ui/react";
import { CustomInput, ErrorMsg, CustomButton } from "components/shared";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { resetPassword } from "apis/tickets";

import { Formik, Form } from "formik";
import resetPasswordValidations from "validations/reset-password";

const NewPasswordForm = ({ token }) => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      navigate("/login");
    },
  });

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={resetPasswordValidations}
      onSubmit={(values) => {
        mutate({ token, data: values });
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              Reset Your Password
            </Text>
            <Text mb="20px" color="#a2a2a2">
              Please enter your new password here and re-enter it for
              confirmation. Ensure that your new password meets the specified
              security requirements. After updating your password, you can log
              in to your account using the new password. If you need assistance,
              please contact us.
            </Text>

            <Box mb="20px">
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={formik.handleChange("newPassword")}
                onBlur={formik.handleBlur("newPassword")}
                value={formik.values.newPassword}
              />
              {formik.errors.newPassword && formik.touched.newPassword && (
                <ErrorMsg text={formik.errors.newPassword} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={formik.handleChange("confirmPassword")}
                onBlur={formik.handleBlur("confirmPassword")}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <ErrorMsg text={formik.errors.confirmPassword} />
                )}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="Submit"
              loading={isLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewPasswordForm;
