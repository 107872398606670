import { useContext } from "react";
import { Box, Text } from "@chakra-ui/react";
import { MoodContext } from "contexts/mood";

const SectionHeader = ({ title, desc, ...props }) => {
  const { mood } = useContext(MoodContext);

  return (
    <Box mb="50px" {...props}>
      <Text
        color={mood ? "text.primaryTextWithDark" : "black"}
        fontWeight="bold"
        fontSize={["20px", "35px", "45px", "45px"]}
        textAlign="center">
        {title}
      </Text>
      <Box w="60px" h="4px" bg="bg.mainColor" m="auto" my="10px" />
      <Text
        color={mood ? "text.textWithDark" : "gray"}
        fontSize="14px"
        textAlign="center"
        w={["95%", "70%", "70%", "70%"]}
        m="auto">
        {desc}
      </Text>
    </Box>
  );
};

export default SectionHeader;
