import { useState, useContext } from "react";
import { Box, Flex, Image, Link as L, Text } from "@chakra-ui/react";
import { CustomContainer, CustomButton } from "components/shared";
import logoLight from "assets/images/logo-white.svg";
import logoDark from "assets/images/logo-dark.svg";
import { Link, useLocation } from "react-router-dom";
// import { userContext } from "contexts/userContext";
import { BiSolidCategory, BiFoodMenu } from "react-icons/bi";

import { IoPeople } from "react-icons/io5";
import { SlLogout } from "react-icons/sl";
import { BiSolidDonateHeart } from "react-icons/bi";

import { AuthContext } from "context/user-auth";
// import { NotificationsModal } from "components/notifications";
import CustomSwitch from "components/shared/custom-switch";
import { MoodContext } from "contexts/mood";

const AuthHeader = () => {
  const { logout, authState } = useContext(AuthContext);
  const { mood } = useContext(MoodContext);

  // const { user } = useContext(userContext);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const { pathname } = useLocation();

  const navbarLinks = [
    {
      name: "your words ",
      link: "/",
      icon: IoPeople,
    },

    { name: "  stories", link: "/stories", icon: BiFoodMenu },
    { name: "podacsts", link: "/podcasts", icon: BiSolidCategory },
  ];

  return (
    <Box
      bg={mood ? "bg.primaryDark" : "white"}
      py="10px"
      borderBottom={mood ? "1px solid #434343" : "2px solid #e0e0e0"}
    >
      <CustomContainer>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Link to="/">
              <Image
                src={mood ? logoLight : logoDark}
                alt="Logo"
                w={["95px", "120px", "", ""]}
                me={["50px", "50px", "20px", "50px"]}
              />
            </Link>

            {/* <UnorderedList
              display="flex"
              listStyleType="none"
              mx={["1em", "1em", "10px", "1em"]}>
              {navbarLinks?.map((e, i) => {
                return (
                  <ListItem
                    me={
                      navbarLinks.length - 1 == i
                        ? "0"
                        : ["20px", "20px", "10px", "20px"]
                    }
                    color={pathname == e?.link ? "text.mainColor" : "#787878"}>
                    <Link to={e.link}>
                      <Flex alignItems="center">
                        <Box as={e.icon} me="5px" fontSize="22px" />
                        <Text fontSize={["initial", "", "14px", "initial"]}>
                          {" "}
                          {e.name}
                        </Text>
                      </Flex>
                    </Link>
                  </ListItem>
                );
              })}
            </UnorderedList> */}
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gap={["8px", "", "15px", "15px"]}
            w="80%"
          >
            <CustomSwitch />

            <L
              href="https://buymeacoffee.com/myvocabs"
              target="_blank"
              display="flex"
              alignItems="center"
              justifyContent="center"
              px={["15px", "30px", "30px", "30px"]}
              py="12px"
              fontWeight="semibold"
              borderRadius="10px"
              color="white"
              fontSize={["14px", "initial", "", ""]}
              bg="#009442"
              _focusVisible={{ boxShadow: "none" }}
            >
              <Box
                as={BiSolidDonateHeart}
                me={["0", "5px", "5px", "5px"]}
                fontSize="24px"
              />
              <Text display={["none", "block", "", ""]}> Support Us</Text>
            </L>

            <CustomButton
              bg="bg.thirdColor"
              hoverColor="bg.hoverThirdColor"
              name="Exit"
              icon={SlLogout}
              onClick={logout}
              w="auto"
              px={["10px", "20px", "20px", "20px"]}
              py={["23px", "25px", "", ""]}
              m="0"
              fontSize={["14px", "", "14px", "initial"]}
              fontWeight={["normal", "bold", "", ""]}
              display={["none", "flex", "", ""]}
            />

            <CustomButton
              bg="bg.thirdColor"
              hoverColor="bg.hoverThirdColor"
              icon={SlLogout}
              onClick={logout}
              w="auto"
              px={["15px", "20px", "20px", "20px"]}
              py={["23px", "25px", "", ""]}
              m="0"
              fontSize={["14px", "", "14px", "initial"]}
              fontWeight={["normal", "bold", "", ""]}
              display={["flex", "none", "", ""]}
            />
          </Flex>
        </Flex>
      </CustomContainer>
    </Box>
  );
};

export default AuthHeader;
