import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Link,
  VStack,
  List,
  ListItem,
  Divider,
} from "@chakra-ui/react";
import { CustomContainer } from "components/shared";

const PrivacySection = ({ heading, text, list }) => (
  <Box width="100%">
    <Heading
      as="h3"
      fontSize="26px"
      fontWeight="bold"
      my={4}
      color="text.mainColor">
      {heading}
    </Heading>
    {text && (
      <Text mb={4} w={["98%", "", "60%", ""]}>
        {text}
      </Text>
    )}
    {list && (
      <List spacing={3} pl={5}>
        {list.map((item, index) => (
          <ListItem key={index}>
            <Text as="span" fontWeight="bold">
              {item.boldText}
            </Text>{" "}
            {item.text}
          </ListItem>
        ))}
      </List>
    )}
    <Divider my={6} />
  </Box>
);

const PrivacyPolicy = () => {
  return (
    <Box my="50px">
      <CustomContainer>
        <Box>
          <Heading
            as="h1"
            fontWeight="bold"
            lineH="1"
            color="text.mainColor"
            mb="0">
            Privacy Policy
          </Heading>
          <Box
            w="80px"
            h="5px"
            borderRadius="15px"
            bg="bg.mainColor"
            my="10px"
          />
          <Text fontSize="lg" color="gray.500" mb="50px">
            <strong>Last updated:</strong> January 13, 2025
          </Text>

          <PrivacySection
            heading="Information We Collect"
            text="We collect the following types of information when you use MyVocabs:"
            list={[
              {
                boldText: "Personal Information:",
                text: "Name, email address, etc.",
              },
              {
                boldText: "Usage Data:",
                text: "Pages visited, time spent on the site, etc.",
              },
              {
                boldText: "Cookies and Tracking Technologies:",
                text: "For a better user experience.",
              },
            ]}
          />

          <PrivacySection
            heading="How We Use Your Information"
            text="The information we collect is used for the following purposes:"
            list={[
              { boldText: "To provide and improve our services.", text: "" },
              {
                boldText: "To communicate with you about updates and services.",
                text: "",
              },
              {
                boldText: "To respond to customer support inquiries.",
                text: "",
              },
            ]}
          />

          <PrivacySection
            heading="Data Protection"
            text="We take reasonable measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security."
          />

          <PrivacySection
            heading="Sharing Your Information"
            text="We do not sell, trade, or rent your personal information to third parties. However, we may share your data with trusted service providers who help us operate our website and deliver our services."
          />

          <PrivacySection
            heading="Your Rights and Choices"
            text="You have the right to:"
            list={[
              {
                boldText:
                  "Access, update, or delete your personal information.",
                text: "",
              },
              { boldText: "Opt out of marketing emails.", text: "" },
              {
                boldText:
                  "Manage your cookie preferences through your browser settings.",
                text: "",
              },
            ]}
          />

          <PrivacySection
            heading="Third-Party Links"
            text="Our website may contain links to third-party websites. We are not responsible for their privacy practices. We encourage you to review their privacy policies before interacting with those sites."
          />

          <PrivacySection
            heading="Children’s Privacy"
            text="MyVocabs is suitable for children and adults alike. We take special care to ensure a safe and educational experience for users of all ages. If you have any concerns or questions about privacy, please contact us immediately."
          />

          <PrivacySection
            heading="Changes to This Privacy Policy"
            text="We may update this Privacy Policy from time to time. Any changes will be posted here with the updated 'Last updated' date. Please review this policy periodically."
          />

          <PrivacySection
            heading="Contact Us"
            text="If you have any questions or concerns about this Privacy Policy, please contact us at:"
            list={[
              {
                boldText: "Email:",
                text: (
                  <Link
                    href="mailto:support@myvocabs.com"
                    color="text.mainColor"
                    fontWeight="bold">
                    support@myvocabs.com
                  </Link>
                ),
              },
            ]}
          />
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default PrivacyPolicy;
