import { useContext } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import { useMutation } from "react-query";
import { forgotPassword } from "apis/tickets";
import { AuthContext } from "context/user-auth";

import { Formik, Form } from "formik";
import forgetPasswordValidations from "validations/forget-password";

const ForgetPasswordForm = ({ setPersonalInfo }) => {
  const { setAuthState, authState } = useContext(AuthContext);
  const toast = useToast();

  const { mutate, isLoading } = useMutation(forgotPassword, {
    onSuccess: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="Sent  "
              description=" An email has been successfully sent to you."
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: (err) => {
      let error;
      const errorToken = err?.response?.data?.errors?.token;
      const errorEmail = err?.response?.data?.errors?.email;

      if (errorToken) {
        error = errorToken;
      } else if (errorEmail) {
        error = errorEmail;
      } else {
        error = "  Please try again.";
      }

      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"حدث خطأ"}
              description={error}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={forgetPasswordValidations}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              Recover Password
            </Text>
            <Text mb="20px" color="#a2a2a2">
              Please enter the email address associated with your account, and
              we will send you a link to reset your password. If you encounter
              any difficulties, feel free to contact us for assistance.
            </Text>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Email Address"
                placeholder=" Enter your email address"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={formik.errors.email} />
              )}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="Send"
              loading={isLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgetPasswordForm;
