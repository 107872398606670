import { useContext, useEffect } from "react";
import { Box, Grid } from "@chakra-ui/react";
import Layout from "layout";
import {
  Home,
  Stories,
  Story,
  Landing,
  Podcasts,
  ForgetPassword,
  ResetPassword,
  Verify,
  PrivacyPolicy,
  Pay,
} from "pages";
import { AuthContext } from "context/user-auth";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { configureAxios } from "./axios-config";

import { Loading } from "components/shared";
import Signup from "pages/signup";
import Login from "pages/login";

import MoodProvider from "contexts/mood";

const App = () => {
  const { authState } = useContext(AuthContext);
  const token = localStorage.getItem("myVocabs-token");

  configureAxios(token);

  // if (authState.token && authState.user == null) {
  //   return (
  //     <Grid placeItems="center" minH="100vh">
  //       <Loading />
  //     </Grid>
  //   );
  // }

  return (
    <MoodProvider>
      <Layout>
        {authState?.token ? (
          <Routes>
            <Route path="/" element={<Home />} />

            {/* <Route path="/stories" element={<Stories />} />
              <Route path="/podcasts" element={<Podcasts />} />
              <Route path="/stories/1" element={<Story />} /> */}

            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        )}
      </Layout>
    </MoodProvider>

    // <Routes>
    //   <Route path="/home" element={<LandingPage />} />
    //   <Route path="/signup" element={<Signup />} />
    //   <Route path="/signin" element={<Signin />} />
    //   <Route path="/review" element={<Review />} />

    //   <Route path="*" element={<Navigate to="/signup" />} />
    // </Routes>
  );
};

export default App;
