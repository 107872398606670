import { Box, Flex, Grid, Text, Image } from "@chakra-ui/react";
import mobileReview from "assets/images/Mockup.png";
import { CustomContainer, CustomButton } from "components/shared";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Landing = () => {
  return (
    <>
      <Box h="100%" bg="#f7f7f8" py={["20px", "", "100px", ""]} id="home">
        <CustomContainer h="100%">
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
            ]}
            placeItems="center"
            h="100%">
            <Box
              w={["95%", "80%", "80%", "80%"]}
              m={["auto", "", "0", ""]}
              order={["2", "2", "1", "1"]}>
              <Text
                textAlign={["center", "", "initial", ""]}
                fontSize={["30px", "35px", "45px", "50px"]}
                fontWeight="bold"
                lineHight="10px"
                mb="15px">
                Save and Explore Your Words Anytime
              </Text>
              <Text
                textAlign={["center", "", "initial", ""]}
                fontSize={["14px", "16px", "", ""]}>
                Sign up now to start saving, organizing, and revisiting your
                favorite words. Plus, enhance your experience by using our Save
                Words Chrome Extension to effortlessly save words as you browse
                the web and improve your vocabulary on the go!
              </Text>
              <Flex
                alignItems="center"
                gap="10px"
                mt="15px"
                justifyContent={["center", "", "initial", ""]}>
                <CustomButton
                  as={Link}
                  to="/signup"
                  name="Singup Now!"
                  w={["48%", "150px", "150px", "150px"]}
                  mx="0"
                />
                <CustomButton
                  as={Link}
                  to="/login"
                  name="Login"
                  w={["48%", "150px", "150px", "150px"]}
                  mx="0"
                  bg={"#f9f9f9"}
                  hoverColor={"#f9f9f9"}
                  border={"1px solid #a9a9a9"}
                  color={"black"}
                  _hover={{}}
                />
              </Flex>
            </Box>
            <Box order={["1", "1", "2", "2"]} my={["20px", "0", "", ""]} mb="0">
              <motion.div
                animate={{
                  y: [20, -20, 20, -20],
                }}
                transition={{
                  duration: 5,
                  // ease: "easeInOut",
                  repeatType: "reverse",
                  repeat: Infinity,
                  type: "tween",
                }}>
                <Image
                  src={mobileReview}
                  w={["80%", "60%", "initial", "2500px"]}
                  m={["auto", "", "0", ""]}
                />
              </motion.div>
            </Box>
          </Grid>
        </CustomContainer>
      </Box>
    </>
  );
};

export default Landing;
