import { useEffect } from "react";
import { Box, Text, Flex, useToast } from "@chakra-ui/react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
import { FaExternalLinkAlt } from "react-icons/fa";

const CustomToast = ({
  title,
  description,
  onClose,
  status,
  extraAction,
  extraActionText,
}) => {
  return (
    <Box
      bg={
        status == "success"
          ? "#46c446"
          : status == "warning"
          ? "#fdbd72"
          : "#d11717"
      }
      p="10px"
      borderRadius="10px"
      minW="200px"
      pos="relative">
      <Flex justifyContent="space-between">
        <Flex>
          <Box
            as={
              status == "success" || status == "warning"
                ? BsFillCheckCircleFill
                : RiErrorWarningFill
            }
            color="white"
            me="5px"
          />
          <Box mt="-5px" fontSize={["14px", "16px", "", ""]}>
            <Text color="white" fontWeight="bold">
              {title}
            </Text>
            <Text color="white">
              {description}{" "}
              {extraAction ? (
                <Text
                  as="span"
                  textDecor="underline"
                  cursor="pointer"
                  onClick={extraAction}>
                  {extraActionText}
                </Text>
              ) : null}
            </Text>
          </Box>
        </Flex>
        <Box
          onClick={onClose}
          as={AiOutlineClose}
          color="white"
          cursor="pointer"
        />
      </Flex>
    </Box>
  );
};

export default CustomToast;
