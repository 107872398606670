import { Box } from "@chakra-ui/react";

const ErrorMsg = ({ text }) => {
  return (
    <Box color="red" fontWeight="bold" fontSize="14px">
      {text}
    </Box>
  );
};

export default ErrorMsg;
