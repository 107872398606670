import { useContext, forwardRef } from "react";
import { Input, Text } from "@chakra-ui/react";
import { MoodContext } from "contexts/mood";

const CustomInput = forwardRef(({ label, labelProps, ...props }, ref) => {
  const { mood } = useContext(MoodContext);

  return (
    <>
      {label && (
        <Text
          mb="5px"
          color={mood ? "text.textWithDark" : "black"}
          {...labelProps}>
          {label}
        </Text>
      )}
      <Input
        ref={ref}
        py="25px"
        borderColor={mood ? "#4d4d4d" : "initial"}
        color={mood ? "text.textWithDark" : "gray"}
        _focus={{ boxShadow: "none" }}
        _active={{}}
        {...props}
      />
    </>
  );
});

export default CustomInput;
