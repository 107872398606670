import { useState, useContext } from "react";
import {
  Box,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { CustomModal } from "components/shared";
import { RiDeleteBin5Line } from "react-icons/ri";
import WordModal from "./word-modal";
import { HiOutlineDotsVertical } from "react-icons/hi";
import AddWord from "./add-word-modal";
import { useMutation } from "react-query";
import { deleteWord } from "apis/tickets";
import { MoodContext } from "contexts/mood";

const WordCard = ({ data, refetch }) => {
  const { mood } = useContext(MoodContext);

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { mutate, isLoading } = useMutation(deleteWord, {
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <>
      <Box
        pos="relative"
        p="15px"
        py="22px"
        bg={mood ? "bg.primaryDark" : "#ededed"}
        borderRadius="15px"
        textAlign="center"
        // cursor="pointer"
        // onClick={() => setShow(true)}
      >
        <Text
          fontSize={["14px", "16px", "", ""]}
          color={mood ? "text.textWithDark" : "black"}>
          {" "}
          {data?.name}{" "}
        </Text>

        <Menu>
          <MenuButton
            pos="absolute"
            top="5px"
            right="5px"
            asChild
            onClick={(e) => e.stopPropagation()}>
            <Grid
              placeItems="center"
              w="25px"
              h="25px"
              borderRadius="50%"
              bg={mood ? "bg.secondaryDark" : "#d5d5d5"}
              // "#383838"
              cursor="pointer">
              <Box
                as={HiOutlineDotsVertical}
                color={mood ? "white" : "black"}
                fontSize="14px"
              />
            </Grid>
          </MenuButton>
          <MenuList
            minW="25px"
            border="none"
            p="0"
            borderRadius="5px"
            overflow="hidden"
            color={mood ? "text.textWithDark" : "gray"}
            bg={mood ? "bg.secondaryDark" : "white"}>
            <MenuItem
              bg="transparent"
              _hover={{ bg: "#e0e0e0", color: "black" }}
              onClick={() => setShow(true)}>
              View
            </MenuItem>
            <MenuItem
              bg="transparent"
              _hover={{ bg: "#e0e0e0", color: "black" }}
              onClick={() => setShowEdit(true)}>
              Edit
            </MenuItem>

            <MenuItem
              bg="transparent"
              _hover={{ bg: "#e0e0e0", color: "black" }}
              onClick={() => mutate(data?._id)}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <CustomModal show={show} setShow={setShow}>
        <WordModal data={data} />
      </CustomModal>

      <CustomModal title="Update Word" show={showEdit} setShow={setShowEdit}>
        <AddWord data={data} refetch={refetch} closeModal={setShowEdit} />
      </CustomModal>
    </>
  );
};

export default WordCard;
