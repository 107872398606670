import { useContext, useRef } from "react";
import { Box, Text, Flex, Grid } from "@chakra-ui/react";
import { MoodContext } from "contexts/mood";
import { MdVolumeUp } from "react-icons/md";

const WordModal = ({ data }) => {
  const { mood } = useContext(MoodContext);
  const audioRef = useRef();

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent={[
          (data.examples?.length > 0 && data.examples[0].text !== "") ||
          data?.defination
            ? "start"
            : "center",
        ]}
        mb="15px">
        <Text
          fontSize="30px"
          fontWeight="bold"
          color={mood ? "text.primaryTextWithDark" : "black"}>
          {data?.name}
        </Text>

        {data?.audio ? (
          <>
            <Grid
              placeItems="center"
              w="35px"
              h="35px"
              bg="bg.mainColor"
              borderRadius="50%"
              ms="10px"
              cursor="pointer"
              onClick={() => {
                audioRef.current.play();
              }}>
              <Box as={MdVolumeUp} fontSize="22px" color="white" />
            </Grid>

            <Box display="none">
              {" "}
              <audio hidden src={data?.audio} ref={audioRef} controls />
            </Box>
          </>
        ) : null}
      </Flex>

      {!data?.defination ? null : (
        <>
          <Text
            fontWeight="bold"
            mb="15px"
            color={mood ? "text.primaryTextWithDark" : "black"}>
            Defination
          </Text>
          <Text color={mood ? "text.textWithDark" : "black"}>
            {data?.defination}
          </Text>

          <Box
            w="100%"
            h="1px"
            border="1px solid "
            borderColor={mood ? "#4d4d4d" : "#f1f1f1"}
            my="10px"
          />
        </>
      )}

      {data.examples?.length > 0 && data.examples[0].text !== "" ? (
        <>
          <Text
            fontWeight="bold"
            mb="15px"
            color={mood ? "text.primaryTextWithDark" : "black"}>
            Examples
          </Text>
        </>
      ) : null}

      {data?.examples?.map((e, i) => {
        return (
          <>
            <Text color={mood ? "text.textWithDark" : "black"}>{e?.text}</Text>

            {data.examples?.length - 1 == i ? null : (
              <Box
                w="100%"
                h="1px"
                border="1px solid "
                borderColor={mood ? "#4d4d4d" : "#f1f1f1"}
                my="10px"
              />
            )}
          </>
        );
      })}
    </Box>
  );
};

export default WordModal;
