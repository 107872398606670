import { useContext } from "react";
import AuthHeader from "./auth-header";
import UnAuthHeader from "./unauth-header";
import { AuthContext } from "context/user-auth";

const Header = () => {
  const { authState } = useContext(AuthContext);

  if (authState?.token) {
    return <AuthHeader />;
  } else {
    return <UnAuthHeader />;
  }
};

export default Header;
