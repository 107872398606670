import { Box, Text, Spinner, Flex, useToast } from "@chakra-ui/react";
import { CustomToast } from "components/shared";
import { AuthContext } from "context/user-auth";
import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Verify = () => {
  const { setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access query parameters
  const userToken = queryParams.get("userToken");

  const checkToken = async (token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/refresh-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();

        localStorage.setItem("myVocabs-token", data?.token);

        setAuthState({
          isAuthenticated: true,
          user: data.user,
          token: data.token,
        });
      } else {
        navigate("/");
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"An error occurred"}
                description="Something went wrong. Please try again."
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //call verfiy function and send token to it after response update local and context state

  useEffect(() => {
    checkToken(userToken);
  }, [userToken]);

  return (
    <Box
      pos="fixed"
      w="100%"
      h="100%"
      left="0"
      top="0"
      zIndex="555555555"
      bg="white">
      <Flex alignItems="center" justifyContent="center" gap="10px" mt="80px">
        <Text fontSize="22px">Verifying </Text>
        <Spinner />
      </Flex>
    </Box>
  );
};

export default Verify;
