import * as Yup from "yup";

const resetPasswordValidations = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "Password must be more than 6 characters")
    .max(100, "Password must be less than 100 characters")
    .required("This field is required"),

  confirmPassword: Yup.string()
    .min(6, "Confirm password must be more than 6 characters")
    .max(100, "Confirm password must be less than 100 characters")
    .required("This field is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export default resetPasswordValidations;
