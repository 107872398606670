import { useContext } from "react";
import { Text } from "@chakra-ui/react";
import { MoodContext } from "contexts/mood";

const Label = ({ text }) => {
  const { mood } = useContext(MoodContext);

  return (
    <Text
      textTransform="capitalize"
      color={mood ? "text.textWithDark" : "gray"}
      fontSize="14px"
      mb="5px"
      fontWeight="semibold">
      {text}
    </Text>
  );
};

export default Label;
