import * as Yup from "yup";

const loginValidations = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),

  password: Yup.string()
    .min(6, "Password must be more than 6 characters")
    .max(100, "Password must be less than 100 characters")
    .required("This field is required"),
});

export default loginValidations;
