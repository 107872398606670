import { useState, useEffect, useRef, useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import OutsideClickHandler from "react-outside-click-handler";
import { MoodContext } from "contexts/mood";

const CustomSelect = ({
  selections,
  placeholder,
  label,
  select,
  inputBg = "#f8f9fa",
  colorLabel = "gray",
  handleSelect,
  maxMinueH = "400px",
  selectBoxWidth = "initial",
  isDisabled,
  ...props
}) => {
  const { mood } = useContext(MoodContext);

  const [value, setValue] = useState(select || "");
  const [openMenu, setOpenMenu] = useState(false);

  const selectButton = useRef();

  const changeValue = (value) => {
    setValue(value);
    handleSelect(value);
  };

  useEffect(() => {
    setValue(select);
  }, [select]);

  const onClickOutSide = () => {
    setOpenMenu(false);
  };

  document.body.onkeydown = (e) => {
    if (e.key == "Escape") {
      onClickOutSide();
    }
  };

  return (
    <Box pos="relative" w={selectBoxWidth}>
      {label && (
        <Box mb="5px" color={colorLabel} fontSize={["14px", "16px", "", ""]}>
          {label}
        </Box>
      )}

      <OutsideClickHandler
        onOutsideClick={() => {
          onClickOutSide();
        }}>
        <Flex
          p="5px"
          px="12px"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #E2E8F0"
          borderColor={mood ? "#4d4d4d" : "#E2E8F0"}
          cursor={isDisabled ? "not-allowed" : "pointer"}
          onClick={() => setOpenMenu(!openMenu)}
          ref={selectButton}
          bg={mood ? "bg.primaryDark" : "white"}
          color={mood ? "text.primaryTextWithDark" : "#7a809d"}
          borderRadius="5px"
          {...props}>
          <Text>{value?.name || placeholder}</Text>
          <Box as={IoIosArrowDown} ms="10px" />
        </Flex>

        {openMenu && !isDisabled && (
          <Box
            // transition="0.3s"
            bg={mood ? "bg.secondaryDark" : "white"}
            p="5px"
            borderRadius="5px"
            mt="5px"
            pos="absolute"
            w="100%"
            zIndex="11"
            boxShadow="0 1px 1px 1px #c3c3c3"
            maxH={maxMinueH}
            overflowY="auto">
            {selections?.map((e, i) => {
              return (
                <Box
                  key={i}
                  cursor="pointer"
                  p="5px"
                  transition="0.3s"
                  _hover={{
                    bg: e.id == value.id ? "#27ADF5" : "#f3f3f3",
                  }}
                  bg={e.id == value.id ? "#27ADF5" : "transparent"}
                  color={e.id == value.id ? "white" : mood ? "white" : "black"}
                  onClick={() => {
                    changeValue(e);
                    setOpenMenu(false);
                  }}>
                  {e.name}
                </Box>
              );
            })}
          </Box>
        )}
      </OutsideClickHandler>
    </Box>
  );
};

export default CustomSelect;
