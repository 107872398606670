import { Box } from "@chakra-ui/react";
import { useState } from "react";
import PhoneField from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhone = ({ ...props }) => {
  const [phone, setPhone] = useState("");

  return (
    <Box dir="ltr">
      <PhoneField
        buttonClass={"codeListBtn"}
        inputClass={"codeListInput"}
        dropdownClass={"codeListDrop"}
        country={"eg"}
        countryCodeEditable={false}
        buttonStyle={{
          backgroundColor: "white",
          borderStartStartRadius: "10px",
          borderEndStartRadius: "10px",
          borderInlineEnd: 0,
        }}
        style={{
          borderRadius: "10px",
        }}
        inputStyle={{
          height: "55px",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #BFCFD6",
          borderRadius: "10px",
          outline: "none",
          fontSize: 15,
        }}
        placeholder="رقم الهاتف هنا"
        {...props}
      />
    </Box>
  );
};

export default CustomPhone;
