import { useContext } from "react";
import { Box, Text, Button, useToast, Link as L } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";

import { Formik, Form } from "formik";

import signupPersonalValidations from "validations/signup-personal";
import { useMutation } from "react-query";
import { signup } from "apis/tickets";
import { AuthContext } from "context/user-auth";

const SignupPersonalForm = () => {
  const { setAuthState } = useContext(AuthContext);

  const toast = useToast();

  const { mutate, isLoading } = useMutation(signup, {
    onSuccess: (res) => {
      localStorage.setItem("myVocabs-token", res?.data?.token);
      window.dispatchEvent(new Event("authTokenSet"));

      setAuthState({
        isAuthenticated: true,
        user: res?.data.user,
        token: res?.data.token,
      });
    },
    onError: (err) => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"An error occurred"}
              description={err?.response?.data?.errors?.alreadyExist}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        name: "",

        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={signupPersonalValidations}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              New Account
            </Text>
            <Text mb="20px" color="#a2a2a2">
              Sign up now to save and manage your words, and be the first to
              explore all the tools we offer to enhance your experience.
            </Text>

            <Button
              bg="transparent"
              border="1px solid black"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="auto"
              w="100%"
              px="30px"
              py="25px"
              borderRadius="10px"
              color="white"
              fontSize={["14px", "initial", "", ""]}
              mb="10px"
              as={L}
              href={`${process.env.REACT_APP_API_BASE_URL}/auth/google `}
              _hover={{ textDecor: "none" }}
              _active={{}}>
              <Box as={FcGoogle} fontSize="24px" />
              <Text color="black" ms="5px">
                Sign up with Google
              </Text>
            </Button>

            <Text textAlign="center" my="10px">
              Or
            </Text>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Name"
                placeholder="Enter your name"
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <ErrorMsg text={formik.errors.name} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Email Address"
                placeholder="Enter your email address"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={formik.errors.email} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Password"
                placeholder="Enter your password"
                type="password"
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMsg text={formik.errors.password} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Confirm Password"
                type="password"
                placeholder="Re-enter your password to confirm"
                onChange={formik.handleChange("confirmPassword")}
                onBlur={formik.handleBlur("confirmPassword")}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <ErrorMsg text={formik.errors.confirmPassword} />
                )}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="Send"
              loading={isLoading}
            />

            <Text textAlign="center">
              Already have an account?{" "}
              <Text
                as={Link}
                to="/login"
                fontWeight="bold"
                color="text.mainColor">
                Log in now
              </Text>
            </Text>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignupPersonalForm;
