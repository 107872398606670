import { useContext } from "react";
import { Box, Text, useToast, Button, Link as L } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { login } from "apis/tickets";
import { AuthContext } from "context/user-auth";
import { Formik, Form } from "formik";
import loginValidations from "validations/login";
import { FaGoogle } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

const LoginForm = ({ setPersonalInfo }) => {
  const toast = useToast();
  const { setAuthState, authState } = useContext(AuthContext);

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: (res) => {
      localStorage.setItem("myVocabs-token", res?.data?.token);
      window.dispatchEvent(new Event("authTokenSet"));

      setAuthState({
        isAuthenticated: true,
        user: res?.data.user,
        token: res?.data.token,
      });
    },
    onError: (err) => {
      const errors = err?.response?.data;

      if (errors?.password) {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title="An Error Occurred"
                description={errors?.password}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      } else if (errors?.email) {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title="An Error Occurred"
                description={errors?.email}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      }
    },
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginValidations}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              Welcome Back!
            </Text>
            <Text mb="20px" color="#a2a2a2">
              Sign in to explore and manage your growing vocabulary collection.
              Stay organized, track your progress, and keep learning!
            </Text>

            <Button
              bg="transparent"
              border="1px solid black"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="auto"
              w="100%"
              px="30px"
              py="25px"
              borderRadius="10px"
              color="white"
              fontSize={["14px", "initial", "", ""]}
              mb="10px"
              as={L}
              href={`${process.env.REACT_APP_API_BASE_URL}/auth/google `}
              _hover={{ textDecor: "none" }}
              _active={{}}>
              <Box as={FcGoogle} fontSize="24px" />
              <Text color="black" ms="5px">
                Sign In with Google
              </Text>
            </Button>

            <Text textAlign="center" my="10px">
              or
            </Text>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Email Address"
                placeholder="Enter your email"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={formik.errors.email} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                labelProps={{ color: "black" }}
                color="black"
                label="Password"
                placeholder="Enter your password"
                type="password"
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMsg text={formik.errors.password} />
              )}
            </Box>

            <Text mb="20px">
              Forgot your password?{" "}
              <Text
                as={Link}
                to="/forget-password"
                display="inline"
                color="text.mainColor">
                Click here
              </Text>
            </Text>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="Submit"
              loading={isLoading}
            />

            <Text textAlign="center">
              Don't have an account?{" "}
              <Text
                as={Link}
                to="/signup"
                fontWeight="bold"
                color="text.mainColor">
                Sign Up Now
              </Text>
            </Text>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
