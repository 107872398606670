import { useState } from "react";
import { Box, Flex, Text, Grid, Image } from "@chakra-ui/react";
import login from "assets/images/login1.svg";

import { LoginForm } from "components/login";

const Login = () => {
  const [personalInfo, setPersonalInfo] = useState();
  const [placeInfo, setPlaceInfo] = useState();

  return (
    <>
      <Grid minH="100vh" my={["20px", "", "0", ""]}>
        <Box
          bg="white"
          borderRadius="5px"
          overflow="hidden"
          w={["90%", "90%", "80%", "65%"]}
          m="auto"
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"

          //border="4px solid #d7a73c"
        >
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
            ]}
            gap="15px">
            <Box
              p={["15px", "23px", "43px", "43px"]}
              order={["2", "1", "", ""]}>
              <LoginForm />
            </Box>

            <Grid
              p={["20px", "20px", "50px", "50px"]}
              bg="#f4f4f4"
              placeItems="center"
              order={["1", "2", "", ""]}>
              <Box>
                <Image
                  src={login}
                  w={["90%", "100%", "100%", "100%"]}
                  m="auto"
                  mb="15px"
                />

                <Text textAlign="center" fontSize="30px">
                  Discover and organize your favorite words with ease.{" "}
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Login;
