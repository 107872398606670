import { useState, useContext, useEffect, useRef } from "react";
import { Box, Grid, useToast } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomButton,
  Label,
  CustomToast,
} from "components/shared";
import { Formik, Form, FieldArray } from "formik";
// import AddNewValidations from "validations/add-person";
import { useMutation, useQueryClient, useQuery } from "react-query";

import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomTextarea from "components/shared/custom-textarea";
import { addWord, updateWord } from "apis/tickets";
import { MoodContext } from "contexts/mood";
import addWordValidations from "validations/add-word";

const Example = ({ remove, index, formik, example, text, ...props }) => {
  return (
    <Box
      border="1px solid #e7e7e7"
      borderRadius="5px"
      p="10px"
      mb="20px"
      pos="relative"
      {...props}>
      <Box>
        <CustomInput
          placeholder="Enter an example sentence using the word"
          labelProps={{ color: "gray", fontSize: "14px" }}
          name={text}
          value={example.text}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label={`Example ${index + 1}`}
        />
        {/* {formik.errors.name && formik.touched.name && (
                  <ErrorMsg text={formik.errors.name} />
                )} */}
      </Box>

      {index != 0 ? (
        <Grid
          placeItems="center"
          w="35px"
          h="35px"
          borderRadius="50%"
          cursor="pointer"
          border="1px solid #e7e3e3"
          bg="white"
          pos="absolute"
          top="-15px"
          right={["-3px", "-15px", "-15px", "-15px"]}
          color="red"
          transition="0.3s"
          _hover={{
            bg: "red",
            color: "white",
            border: "none",
          }}
          onClick={() => remove(index)}>
          <Box as={RiDeleteBin6Line} />
        </Grid>
      ) : null}
    </Box>
  );
};

const AddWord = ({ data, refetch, closeModal, updateExistingWord }) => {
  const { mood } = useContext(MoodContext);
  const inputWordRef = useRef();
  const toast = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Focus input after modal is opened
    if (inputWordRef.current) {
      inputWordRef.current.focus();
    }
  }, [inputWordRef]);

  const { mutate: add, isLoading: isLoadingAdd } = useMutation(addWord, {
    onSuccess: () => {
      refetch();
      queryClient.invalidateQueries("get words");
      closeModal();
    },
    onError: (err) => {
      const word = err?.response?.data?.word;
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"An error occurred"}
              description={word ? word?.message : "Something went wrong"}
              extraActionText={word ? "update it" : null}
              extraAction={() => {
                updateExistingWord(word?.wordData);
                closeModal();
              }}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });
  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    updateWord,
    {
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries("get words");
        closeModal();
      },
    }
  );

  return (
    <Formik
      initialValues={{
        name: data ? data?.name : "",
        defination: data ? data?.defination : "",
        examples: data
          ? data?.examples
          : [
              {
                text: "",
              },
            ],
      }}
      validationSchema={addWordValidations}
      validateOnBlur={false} // Disable validation on blur
      onSubmit={(values) => {
        const examples = values?.examples?.filter((e, i) => {
          return i === 0 || e?.text !== "";
        });

        if (data) {
          update({ id: data?._id, data: { ...values, examples } });
        } else {
          add({ ...values, examples });
        }
        //handleLogin({ email: values.email, password: values.password });
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Box mb="20px">
              <CustomInput
                ref={inputWordRef}
                label="Word Name"
                labelProps={{
                  fontWeight: "semibold",
                  color: "gray",
                  fontSize: "14px",
                }}
                placeholder="Enter the word"
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <ErrorMsg text={formik.errors.name} />
              )}
            </Box>

            <Box mb="20px">
              <Label text="Definition" />
              <CustomTextarea
                borderColor={mood ? "#4d4d4d" : "initial"}
                color={mood ? "text.textWithDark" : "gray"}
                placeholder="Enter the definition of the word"
                h="100px"
                onChange={formik.handleChange("defination")}
                onBlur={formik.handleBlur("defination")}
                value={formik.values.defination}
              />
              {formik.errors.defination && formik.touched.defination && (
                <ErrorMsg text={formik.errors.defination} />
              )}
            </Box>

            <Label text="Examples" />

            <FieldArray name="examples">
              {({ push, remove }) => (
                <>
                  {formik.values.examples.map((e, i) => {
                    const text = `examples[${i}].text`;

                    return (
                      <Example
                        remove={remove}
                        index={i}
                        formik={formik}
                        text={text}
                        example={e}
                        borderColor={mood ? "#4d4d4d" : "#e7e7e7"}
                      />
                    );
                  })}

                  {formik.values.examples.length >= 4 ? null : (
                    <Grid
                      title="Add new example"
                      placeItems="center"
                      w="50px"
                      h="50px"
                      bg={mood ? "bg.secondaryDark" : "transparent"}
                      borderRadius="50%"
                      border="1px solid #e7e7e7"
                      m="auto"
                      cursor="pointer"
                      transition="0.3s"
                      color={mood ? "text.textWithDark" : "black"}
                      _hover={{
                        bg: mood ? "bg.secondaryDark" : "bg.mainColor",
                        color: "white",
                      }}
                      onClick={() => push({ text: "" })}>
                      <Box as={IoMdAdd} fontSize="20px" />
                    </Grid>
                  )}
                </>
              )}
            </FieldArray>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="Save"
              isLoading={isLoadingAdd || isLoadingUpdate}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddWord;
