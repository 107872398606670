import { useState, useEffect } from "react";
import { Box, Flex, Image, Text, Button, Link as L } from "@chakra-ui/react";
import { CustomContainer } from "components/shared";
import logoDark from "assets/images/logo-dark.svg";
import chrome from "assets/images/chrome.png";
import { Link, useLocation } from "react-router-dom";
// import { userContext } from "contexts/userContext";
// import { NotificationsModal } from "components/notifications";

const UnAuthHeader = () => {
  // const { user } = useContext(userContext);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const { pathname } = useLocation();

  const navbarLinks = [
    // {
    //   name: "your words ",
    //   link: "/#features",
    //   icon: IoPeople,
    // },
    // { name: "  stories", link: "/stories", icon: BiFoodMenu },
    // { name: "podacsts", link: "/podcasts", icon: BiSolidCategory },
    // {
    //   name: "blogs",
    //   link: "/blogs",
    //   icon: IoPeople,
    // },
  ];
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      transition="0.3s"
      // pos={pathname == "/" ? "fixed" : "relative"}
      zIndex="11"
      w="100%"
      bg="white"
      py="10px"
      borderBottom="2px solid #e0e0e0"
      borderColor="#e0e0e0">
      <CustomContainer>
        <Flex alignItems="center" justify="space-between">
          <Flex maxW="container" alignItems="center">
            <Link to="/">
              <Image src={logoDark} alt="Logo" w="120px" me="50px" />
            </Link>
          </Flex>

          <Button
            as={L}
            target="_blank"
            py="24px"
            fontWeight="normal"
            textDecoration="none"
            href="https://chromewebstore.google.com/detail/save-words/amokgdijmanjfcakbdjjecfodolfepgl?authuser=0&hl=en"
            bg="transparent"
            border="1px solid black"
            hoverColor="#fcbf66"
            _hover={{ textDecoration: "none" }}
            _active={{}}>
            <Image src={chrome} w="25px" me="5px" />
            <Text>Add To Chrome</Text>
          </Button>

          {/* <CustomButton as={Link} to="/login" name=" login" /> */}
        </Flex>
      </CustomContainer>
    </Box>
  );
};

export default UnAuthHeader;
