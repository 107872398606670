import { useState, useContext, useEffect } from "react";
import { Box, Flex, Grid, Button, Spinner } from "@chakra-ui/react";
import {
  CustomButton,
  CustomContainer,
  CustomModal,
  CustomSelect,
  Loading,
  Nodata,
  SectionHeader,
} from "components/shared";
import {
  WordCard,
  AddWord,
  ConfirmationModal,
  GenerateFile,
} from "components/your-words";
import { useInView } from "react-intersection-observer";
import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import { deleteWords, getWords, getWordsByPagination } from "apis/tickets";
import { MoodContext } from "contexts/mood";
import { MdAdd } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { FiDownload } from "react-icons/fi";

const Home = () => {
  const { ref, inView } = useInView();
  const fileTypes = [
    {
      name: "PDF",
      id: "pdf",
    },
    {
      name: "CSV",
      id: "csv",
    },
  ];

  const [fileType, setFileType] = useState(fileTypes[0]);
  const [showAddWord, setShowAddWord] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateExisting, setShowUpdateExisting] = useState(false);
  const [existingWord, setExistingWord] = useState(null);

  const updateExistingWord = (data) => {
    setExistingWord(data);
    setShowUpdateExisting(true);
  };

  const handleFileType = (value) => {
    setFileType(value);
  };

  const {
    isLoading,
    isError,
    refetch,
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(["get all words"], getWordsByPagination, {
    getNextPageParam: (_lastPage, pages) => {
      if (pages.length < pages[0]?.data?.total / 10) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
  });

  const { mutate, isLoading: isLoadingDeleteAll } = useMutation(deleteWords, {
    onSuccess: () => {
      setShowConfirm(false);
      refetch();
    },
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const { mood } = useContext(MoodContext);

  return (
    <Box py="50px" h="100%" bg={mood ? "#272B2F" : "white"}>
      <CustomContainer>
        <SectionHeader
          title="  Your Vocabulary Hub "
          desc=" Here are your saved words! Add new vocabulary, complete with definitions and examples to enrich your understanding. You can easily manage your list by removing all entries at once or downloading your collection as a PDF or CSV file. Start building your personalized lexicon today!"
        />

        {data?.pages[0]?.data?.total > 0 ? (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
              display={["none", "flex", "", ""]}>
              <CustomButton
                name="Add word"
                w="220px"
                m="0"
                py="22px"
                onClick={() => setShowAddWord(true)}
              />
              <Flex alignItems="center" gap="15px">
                <CustomButton
                  name="Remove all "
                  m="0"
                  bg="bg.thirdColor"
                  hoverColor="bg.hoverThirdColor"
                  py="22px"
                  onClick={() => setShowConfirm(true)}
                />
                <GenerateFile type={fileType.id} data={data?.data} />

                <CustomSelect
                  //   label="عدد النجوم"
                  select={fileType}
                  selections={fileTypes}
                  handleSelect={handleFileType}
                  placeholder="pdf"
                  h="42px"
                  w="70px"
                  px="10px"
                  // w={["100%", "100%", "initial", ""]}
                  fontSize={["14px", "initial", "", ""]}
                />
              </Flex>
            </Flex>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
              display={["flex", "none", "", ""]}>
              <CustomButton
                w="60px"
                px="0"
                icon={MdAdd}
                m="0"
                py="22px"
                onClick={() => setShowAddWord(true)}
              />
              <Flex alignItems="center" gap="5px">
                <CustomButton
                  name="All"
                  icon={IoMdTrash}
                  px="15px"
                  m="0"
                  bg="bg.thirdColor"
                  hoverColor="bg.hoverThirdColor"
                  py="22px"
                  onClick={() => setShowConfirm(true)}
                />
                <GenerateFile type={fileType.id} data={[]} />

                <CustomSelect
                  //   label="عدد النجوم"
                  select={fileType}
                  selections={fileTypes}
                  handleSelect={handleFileType}
                  placeholder="pdf"
                  h="42px"
                  w="70px"
                  px="10px"
                  // w={["100%", "100%", "initial", ""]}
                  fontSize={["14px", "initial", "", ""]}
                />
              </Flex>
            </Flex>
          </>
        ) : null}

        {isLoading ? (
          <Loading />
        ) : data?.pages[0]?.data?.total ? (
          <>
            <Grid
              gridTemplateColumns={[
                "repeat(2,1fr)",
                "repeat(3,1fr)",
                "repeat(4,1fr)",
                "repeat(6,1fr)",
              ]}
              gap="15px">
              {data?.pages?.map((p) => {
                return (
                  <>
                    {p?.data?.words?.map((e) => (
                      <WordCard data={e} refetch={refetch} />
                    ))}
                  </>
                );
              })}
            </Grid>
            {!hasNextPage ? null : (
              <Button
                mx="auto"
                bg="transparent"
                color={mood ? "text.textWithDark" : "black"}
                ref={ref}
                onClick={() => fetchNextPage()}
                isDisabled={!hasNextPage}
                minH="38px"
                maxH="100%"
                fontWeight="normal"
                border="none"
                borderRadius="5px"
                _hover={{}}
                _focus={{ outline: "none" }}
                _active={{}}
                display="flex"
                alignItems="center"
                my="30px">
                Loading More {isFetchingNextPage ? <Spinner ms="5px" /> : null}
              </Button>
            )}
          </>
        ) : (
          <>
            <Nodata />
            <CustomButton
              name="Add word"
              w="220px"
              m="auto"
              py="22px"
              onClick={() => setShowAddWord(true)}
            />
          </>
        )}
      </CustomContainer>

      <CustomModal
        title="Add New Word"
        show={showAddWord}
        setShow={setShowAddWord}>
        <AddWord
          refetch={refetch}
          closeModal={setShowAddWord}
          updateExistingWord={updateExistingWord}
        />
      </CustomModal>

      <CustomModal
        title="Update  Word"
        show={showUpdateExisting}
        setShow={setShowUpdateExisting}>
        <AddWord
          refetch={refetch}
          closeModal={setShowUpdateExisting}
          data={existingWord}
        />
      </CustomModal>

      <CustomModal
        title="Confirm Deletion "
        show={showConfirm}
        setShow={setShowConfirm}>
        <ConfirmationModal
          message="Are you sure you want to delete all words from your list? This action is irreversible."
          action={mutate}
          isLoading={isLoadingDeleteAll}
          setShow={setShowConfirm}
        />
      </CustomModal>
    </Box>
  );
};

export default Home;
