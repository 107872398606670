import { Box, Flex, Text, Grid, Image } from "@chakra-ui/react";
import signupBg from "assets/images/signup-white.svg";

import { SignupPersonalForm } from "components/signup";

const Signup = () => {
  return (
    <>
      <Grid minH="100vh" my="50px">
        <Box
          bg="white"
          borderRadius="5px"
          overflow="hidden"
          w={["90%", "90%", "80%", "65%"]}
          m="auto"
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"

          //border="4px solid #d7a73c"
        >
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
            ]}
            gap="15px">
            <Box
              p={["15px", "23px", "43px", "43px"]}
              order={["2", "1", "", ""]}>
              <SignupPersonalForm />
            </Box>
            <Grid
              p={["20px", "20px", "50px", "50px"]}
              bg="#f4f4f4"
              order={["1", "2", "", ""]}
              placeItems="center">
              <Box>
                <Image
                  src={signupBg}
                  m="auto"
                  mb="15px"
                  w={["90%", "100%", "100%", "100%"]}
                />

                <Text textAlign="center" fontSize="28px">
                  Save, explore, and revisit your favorite words anytime.
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Signup;
