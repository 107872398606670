import * as Yup from "yup";

const signupPersonalValidations = Yup.object().shape({
  name: Yup.string()
    .max(100, "Name must be less than 100 characters")
    .required("This field is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("This field is required"),

  password: Yup.string()
    .min(6, "Password must be more than 6 characters")
    .max(100, "Password must be less than 100 characters")
    .required("This field is required"),

  confirmPassword: Yup.string()
    .min(6, "Confirm password must be more than 6 characters")
    .max(100, "Confirm password must be less than 100 characters")
    .required("This field is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default signupPersonalValidations;
