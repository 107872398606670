import { useContext } from "react";
import { Box, Grid, Text } from "@chakra-ui/react";
import { CustomButton } from "components/shared";
import { MoodContext } from "contexts/mood";

const ConfirmationModal = ({ message, action, isLoading, setShow }) => {
  const { mood } = useContext(MoodContext);

  return (
    <Box>
      <Text
        textAlign="center"
        mb="40px"
        color={mood ? "text.textWithDark" : "black"}>
        {message}
      </Text>
      <Grid gridTemplateColumns="repeat(2,1fr)" gap="15px">
        <CustomButton
          bg="bg.thirdColor"
          hoverColor="bg.hoverThirdColor"
          name="Yes, Delete All"
          onClick={action}
          loading={isLoading}
        />
        <CustomButton
          bg={mood ? "bg.secondaryDark" : "#f9f9f9"}
          hoverColor={mood ? "bg.secondaryDark" : "#f9f9f9"}
          border={mood ? "1px solid #f9f9f9" : "1px solid black"}
          color={mood ? "text.textWithDark" : "black"}
          name="No, Keep Words"
          onClick={() => setShow(false)}
        />
      </Grid>
    </Box>
  );
};

export default ConfirmationModal;
