import { useContext } from "react";
import { Box, Text, Grid, Link } from "@chakra-ui/react";
import { MoodContext } from "contexts/mood";
import { AuthContext } from "context/user-auth";
import { Link as L } from "react-router-dom";

const SocialMedaiLinks = ({ icon, ...props }) => {
  return (
    <Grid
      placeItems="center"
      border="1px solid black"
      w="50px"
      h="50px"
      borderRadius="5px"
      cursor="pointer"
      {...props}>
      <Box as={icon} fontSize="20px" />
    </Grid>
  );
};

const Footer = () => {
  const { mood } = useContext(MoodContext);
  const { authState } = useContext(AuthContext);

  return (
    <Box
      bg={mood && authState?.user ? "bg.primaryDark" : "#ededed"}
      borderTop={
        mood && authState?.user ? "1px solid #434343" : "2px solid #e0e0e0"
      }>
      <Text
        fontSize={["13px", "16px", "", ""]}
        textAlign="center"
        w={["90%", "60%", "50%", "40%"]}
        m="auto"
        my="15px"
        mb="5px"
        color={mood && authState?.user ? "text.textWithDark" : "gray"}>
        © 2025 myVocabs. All rights reserved.{" "}
        {!authState?.user ? (
          <>
            | Read our{" "}
            <Text
              as={L}
              to="/privacy"
              textDecor="underline"
              color="text.mainColor">
              privacy
            </Text>
          </>
        ) : null}
      </Text>

      <Text
        fontSize={["12px", "15px", "", ""]}
        textAlign="center"
        w={["90%", "60%", "50%", "60%"]}
        m="auto"
        mb="15px"
        color={mood && authState?.user ? "text.textWithDark" : "gray"}>
        For support, contact us at{" "}
        <Link
          href="mailto:support@myvocabs.com"
          textDecor="underline"
          color="text.mainColor">
          support@myvocabs.com
        </Link>
      </Text>

      {/* <Flex alignItems="center" justifyContent="center" w="50%" m="auto">
        <SocialMedaiLinks icon={FaFacebookF} me="10px" />
        <SocialMedaiLinks icon={FaWhatsapp} me="10px" />
        <SocialMedaiLinks icon={FaTwitter} me="10px" />
        <SocialMedaiLinks icon={FaInstagram} />
      </Flex> */}

      {/* <Text textAlign="center" w="50%" m="auto" mt="55px">
        Copyrights All rights revirsed by ibrahem magdy
      </Text> */}
    </Box>
  );
};

export default Footer;
