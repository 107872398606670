import React from "react";
import Papa from "papaparse";
import { jsPDF } from "jspdf";
import { CustomButton } from "components/shared";
import { FiDownload } from "react-icons/fi";
import { getWords } from "apis/tickets";
import { useQuery } from "react-query";

const GenerateFile = ({ type }) => {
  const { data, isLoading, refetch } = useQuery("get words", getWords);

  // Step 1: Sort the array by age in descending order (oldest to newest)
  const sortedNamesPdf = data?.data?.map((item) => item.name); // Extract only the 'name' property
  const sortedNamesCsv = data?.data?.map((item) => ({ name: item.name })); // Extract only the 'name' property

  // Step 2: Convert sorted names to CSV
  const generateCSV = () => {
    // Use PapaParse to convert the sorted names array into CSV
    const csv = Papa.unparse(sortedNamesCsv);

    // Step 3: Trigger the download of the CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "MyVocabs.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const margin = 20;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const columnWidth = (pageWidth - 2 * margin) / 3; // 3 columns
    const maxNamesPerColumn = 28; // Max items per column
    const lineHeight = 10; // Line spacing

    let yPosition = 10;
    let xPosition = margin; // Start at the first column
    let columnCount = 0;

    // Set the title
    doc.setFontSize(18);
    doc.text("MyVocabs.com", xPosition, yPosition);
    yPosition += lineHeight;

    // Add the names to the PDF
    doc.setFontSize(12);
    sortedNamesPdf.forEach((name, index) => {
      // Add name to the current column
      doc.text(`${name}`, xPosition, yPosition);
      yPosition += lineHeight;

      // Check if the current column is full
      if (yPosition > lineHeight * maxNamesPerColumn + 10) {
        // Move to the next column
        yPosition = 10 + lineHeight;
        xPosition += columnWidth;
        columnCount++;

        // If all columns are filled, move to a new page
        if (columnCount === 3) {
          // Reset to first column
          columnCount = 0;
          xPosition = margin;
          yPosition = 10 + lineHeight;
          doc.addPage(); // Start a new page
        }
      }

      // Check if the current page is full after adding the name
      // if (yPosition > pageHeight - lineHeight) {
      //   // Start a new page if the current page is full
      //   doc.addPage();
      //   yPosition = 10; // Reset Y position for the new page
      //   xPosition = margin; // Reset X position for the first column
      //   columnCount = 0; // Reset column count
      // }
    });

    // Save the PDF
    doc.save("MyVocabs.pdf");
  };

  return (
    <>
      <CustomButton
        display={["none", "flex", "", ""]}
        name="Download Words"
        m="0"
        py="22px"
        onClick={() => {
          if (type == "pdf") {
            generatePDF();
          } else if (type == "csv") {
            generateCSV();
          }
        }}
        isDisabled={isLoading}
      />

      <CustomButton
        display={["flex", "none", "", ""]}
        icon={FiDownload}
        px="0"
        m="0"
        py="22px"
        onClick={() => {
          if (type == "pdf") {
            generatePDF();
          } else if (type == "csv") {
            generateCSV();
          }
        }}
        isDisabled={isLoading}
      />
    </>
  );
};

export default GenerateFile;
