import {
  Flex,
  Image,
  Heading,
  Grid,
  Text,
  CircularProgress,
  Skeleton,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";

const Loading = () => {
  const [isSmall, setIsSmall] = useState(false);
  window.onresize = () => {
    if (window.innerWidth < 800) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  };

  return (
    <Grid
      gridTemplateColumns={[
        "repeat(2,1fr)",
        "repeat(3,1fr)",
        "repeat(4,1fr)",
        "repeat(6,1fr)",
      ]}
      gap="15px">
      {isSmall ? (
        <>
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
        </>
      ) : null}

      {!isSmall ? (
        <>
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
          <Skeleton p="15px" py="22px" borderRadius="15px" />
        </>
      ) : null}
    </Grid>
    // <Flex
    //   flexDirection="column"
    //   alignItems="center"
    //   justifyContent="center"
    //   minH="40vh"
    // >
    //   <Flex>
    //     <Heading as="h3" fontWeight="bold" mb="10px" me="10px">
    //       تحميل
    //     </Heading>
    //     <CircularProgress isIndeterminate color="orange" />
    //   </Flex>
    // </Flex>
  );
};

export default Loading;
