import React from "react";
import { Button, Box, Spinner } from "@chakra-ui/react"; // Adjust according to your UI library
import chroma from "chroma-js";

const CustomButton = ({
  name,
  hoverColor, // Allow hoverColor to be passed or fall back to a dynamic value
  loading,
  icon,
  iconSize = "20px",
  bgColor = "#3861fb",
  
  ...props
}) => {
  const defaultHoverColor = chroma(bgColor).brighten(0.2).hex(); // Adjust brightness

  return (
    <Button
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="auto"
      w="100%"
      px="30px"
      py="25px"
      fontWeight="semibold"
      borderRadius="10px"
      color="white"
      fontSize={["14px", "initial", "", ""]}
      bg={bgColor}
      _hover={{ bg: hoverColor || defaultHoverColor }}
      _focusVisible={{ boxShadow: "none" }}
      isDisabled={loading}
      {...props}>
      {icon ? (
        <Box as={icon} fontSize={iconSize} me={name ? "5px" : "0"} />
      ) : null}
      {name}
      {loading ? <Spinner ms="5px" /> : null}
    </Button>
  );
};

export default CustomButton;
