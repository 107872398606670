import { useContext } from "react";
import { Box } from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import { MoodContext } from "contexts/mood";
import { AuthContext } from "context/user-auth";

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  const { mood } = useContext(MoodContext);
  const { authState } = useContext(AuthContext);

  return (
    <Box display="grid" gridTemplateRows="auto 1fr auto" minH="100vh">
      <Header />
      <Box
        minH="450px"
        bg={mood && authState.user ? "bg.secondaryDark" : "white"}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
