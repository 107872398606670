import React, { useState, useContext } from "react";
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import { MoodContext } from "contexts/mood";

const CustomSwitch = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = () => {
    changeMode();
  };

  // To handle responsiveness, you can use Chakra's `useBreakpointValue`
  const switchWidth = useBreakpointValue({ base: "50px", md: "70px" });
  const circleSize = useBreakpointValue({ base: "22px", md: "30px" });
  const { mood, changeMode } = useContext(MoodContext);

  return (
    <Box display="flex" alignItems="center">
      {/* Custom Switch */}
      <Flex
        onClick={handleSwitchChange}
        cursor="pointer"
        display="flex"
        alignItems="center"
        borderRadius="50px"
        gap="5px"
        p={1}
        py="24px"
        w="120px"
        h="30px"
        bg={mood ? "#2D3136" : "#F6F7FA"}
        transition="background-color 0.3s">
        {/* Circle for the switch */}
        <Box
          position="relative"
          zIndex="1111"
          display="grid"
          placeItems="center"
          w="30px"
          h="30px"
          bg="white"
          borderRadius="50%"
          boxShadow="md"
          transition="transform 0.3s"
          transform={
            mood ? `translateX(${parseInt(120) - parseInt(30) - 10}px)` : "none"
          }>
          {mood ? (
            <Box as={FaSun} color="yellow.500" />
          ) : (
            <Box as={FaMoon} color="#212529" />
          )}
        </Box>
        <Text
          color={mood ? "#D8D9DA" : "#4f4f4f"}
          textTransform="capitalize"
          fontSize="13px"
          transform={mood ? `translateX(-30px)` : "none"}
          transition="transform 0.3s">
          {mood ? "Light Mode" : "Dark Mode"}
        </Text>
      </Flex>
    </Box>
  );
};

export default CustomSwitch;
