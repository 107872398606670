import { createContext, useState } from "react";

export const MoodContext = createContext();

const MoodProvider = ({ children }) => {
  const moodFromLocal = localStorage.getItem("vocapsafe-mood");
  const [isMoodDark, setMoodDark] = useState(moodFromLocal === "true");

  const changeMode = () => {
    setMoodDark((prev) => {
      const newMode = !prev;
      // Update localStorage when the state is changed
      localStorage.setItem("vocapsafe-mood", newMode.toString());
      return newMode;
    });
  };

  return (
    <MoodContext.Provider value={{ mood: isMoodDark, changeMode }}>
      {children}
    </MoodContext.Provider>
  );
};

export default MoodProvider;
