import { useContext } from "react";
import { Flex, Image, Heading, Text } from "@chakra-ui/react";
import empty from "assets/images/empty.png";
import { MoodContext } from "contexts/mood";

const Nodata = () => {
  const { mood } = useContext(MoodContext);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt="40px">
      <Image src={empty} w="220px" m="auto" mb="20px" />
      <Heading
        as="h3"
        fontWeight="bold"
        mb="10px"
        color={mood ? "text.primaryTextWithDark" : "gray.600"}
        textAlign="center">
        No data to display{" "}
      </Heading>
    </Flex>
  );
};

export default Nodata;
